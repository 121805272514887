import * as React from 'react';

import styled from '@emotion/styled';
import { Link } from 'gatsby';
import Container from '../components/Container';
import Page from '../components/Page';
import IndexLayout from '../layouts';
import { TrendsTable } from '../components/TrendsTable';
import { PageHeader } from '../components/PageHeader';
import { abbreviateNumber } from '../utils/numbers';

interface Props {
  pageContext: any;
}

const StyledContainer = styled.div`
  margin-top: 30px;
  border: 1px solid black;
  width: 930px;
  padding: 0 15px;

  h1 {
    font-size: 42px;
  }
`;

const StyledContainerFooter = styled.div`
  background: #000;
  color: #ccc;
  width: 1000;
  margin: 0 -16px;
  padding: 4px 5px;
  text-align: right;
  font-size: 11px;
  font-family: ${p => p.theme.fonts.serif};
  letter-spacing: 1px;
`;

export default function ScreeniesTemplate({
  pageContext: {
    spacs,
    dailySpacs,
    pennies,
    penniesRh,
    wsPennies,
    canadaPennies,
    dailyPennies,
    dailyPenniesRh,
    bsb,
    wsb,
    wsbOG,
    daily,
    tomorrow,
    tomorrowOgs,
    renderTime
  }
}: Props) {
  const dateStr = pennies.lastUpdatedEst;
  const comma = dateStr.indexOf(',') + 1;
  const space = dateStr.indexOf(' ', comma + 1);
  const year = dateStr.indexOf('202');

  const month = dateStr.substring(space, year).trim();
  const day = pennies.lastUpdatedEst.substring(comma, space).trim();

  return (
    <IndexLayout>
      <Page>
        <Container>
          <PageHeader time={renderTime} logoOnly />
          <StyledContainer>
            <h1>
              {month} {day} - Pennystocks trends
            </h1>
            <p>
              Top stock tickers popular on the Reddit <a href="https://www.reddit.com/r/pennystocks">pennystocks</a> community, of{' '}
              {abbreviateNumber(pennies.totalSubscribers)} members, over the last {(pennies.days || 1) * 24}-hours.
            </p>
            <TrendsTable data={pennies} lastUpdated={false} volume={false} />
            <h1>
              {month} {day} - Daily Plays trends
            </h1>
            <TrendsTable data={dailyPennies} volume={false} />
            <StyledContainerFooter>wsbdaily.com</StyledContainerFooter>
          </StyledContainer>
          <StyledContainer>
            <h1>
              {month} {day} - RobinHoodPennyStocks trends
            </h1>
            <p>
              Top stock tickers popular on the Reddit <a href="https://www.reddit.com/r/RobinHoodPennyStocks">RobinHoodPennyStocks</a>{' '}
              community, of {abbreviateNumber(penniesRh.totalSubscribers)} members, over the last {(penniesRh.days || 1) * 24}-hours.
            </p>
            <TrendsTable data={penniesRh} lastUpdated={false} volume={false} />
            <h1>
              {month} {day} - Daily Discussion trends
            </h1>
            <TrendsTable data={dailyPenniesRh} volume={false} />
            <StyledContainerFooter>wsbdaily.com</StyledContainerFooter>
          </StyledContainer>
          <StyledContainer>
            <h1>
              {month} {day} - Wealthsimple_Penny trends
            </h1>
            <p>
              Top stock tickers popular on the Reddit <a href="https://www.reddit.com/r/Wealthsimple_Penny">Wealthsimple_Penny</a>{' '}
              community, of {abbreviateNumber(wsPennies.totalSubscribers)} members, over the last {wsPennies.days}-days.
            </p>
            <TrendsTable data={wsPennies} volume={false} />
            <StyledContainerFooter>wsbdaily.com</StyledContainerFooter>
          </StyledContainer>
          <StyledContainer>
            <h1>
              {month} {day} - Baystreetbets trends
            </h1>
            <p>
              Top stock tickers popular on the Reddit <a href="https://www.reddit.com/r/Baystreetbets">Baystreetbets</a> community, of{' '}
              {abbreviateNumber(bsb.totalSubscribers)} members, over the last {(bsb.days || 1) * 24}-hours.
            </p>
            <TrendsTable data={bsb} volume={false} />
            <StyledContainerFooter>wsbdaily.com</StyledContainerFooter>
          </StyledContainer>
          <StyledContainer>
            <h1>
              {month} {day} - Canadapennystocks trends
            </h1>
            <p>
              Top stock tickers popular on the Reddit <a href="https://www.reddit.com/r/Canadapennystocks">Canadapennystocks</a> community,
              of {abbreviateNumber(canadaPennies.totalSubscribers)} members, over the last 48-hours.
            </p>
            <TrendsTable data={canadaPennies} volume={false} />
            <StyledContainerFooter>wsbdaily.com</StyledContainerFooter>
          </StyledContainer>
          <StyledContainer>
            <h1>
              {month} {day} - wallstreetbets trends
            </h1>
            <p>
              Top stock tickers popular on the Reddit <a href="https://www.reddit.com/r/wallstreetbets">wallstreetbets</a> community, of{' '}
              {abbreviateNumber(wsb.totalSubscribers)} members, over the last 24-hours.
            </p>
            <TrendsTable data={wsb} volume={false} lastUpdated={false} />
            <h1>
              {month} {day} - Tomorrow’s Moves Trends
            </h1>
            <TrendsTable data={tomorrow} volume={false} lastUpdated={false} />
            <h1>
              {month} {day} - Daily Discussion Trends
            </h1>
            <TrendsTable data={daily} volume={false} />
            <StyledContainerFooter>wsbdaily.com</StyledContainerFooter>
          </StyledContainer>
          <StyledContainer>
            <h1>
              {month} {day} - Subreddit Trends
            </h1>
            <p>Top stock tickers popular on the subreddit of {abbreviateNumber(wsbOG.totalSubscribers)} members, over the last 24-hours.</p>
            <TrendsTable data={wsbOG} volume={false} lastUpdated={false} />
            <h1>
              {month} {day} - Tomorrow’s Moves Trends
            </h1>
            <TrendsTable data={tomorrowOgs} volume={false} />
            <StyledContainerFooter>wsbdaily.com</StyledContainerFooter>
          </StyledContainer>
          <StyledContainer>
            <h1>
              {month} {day} - SPACs Trends
            </h1>
            <p>
              Top tickers popular on the Reddit <a href="https://www.reddit.com/r/SPACs">SPACs</a> community, of{' '}
              {abbreviateNumber(spacs.totalSubscribers)} members, over the last 24-hours.
            </p>
            <TrendsTable data={spacs} lastUpdated={false} volume={false} />
            <h1>
              {month} {day} - Moves Tomorrow Trends
            </h1>
            <TrendsTable data={dailySpacs} volume={false} />
            <StyledContainerFooter>wsbdaily.com</StyledContainerFooter>
          </StyledContainer>
        </Container>
      </Page>
    </IndexLayout>
  );
}
